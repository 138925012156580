/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, Button, Subtitle } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Terapeutka Petra Klempířová - Ceník"}>
        <SiteHeader />

        <Column className="--style2 --left --full" name={"uvod"} style={{"backgroundColor":"rgba(157,150,140,1)"}} fullscreen={true}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s3 el--1 flex--center" anim={"2"} animS={"3"} style={{"maxWidth":1310}} columns={"1"}>
            
            <ColumnWrapper className="pb--0 pl--0 pr--0 pt--0" anim={""} animS={"3"} style={{"maxWidth":""}}>
              
              <Title className="title-box fs--72" style={{"maxWidth":""}} content={"Ceník<br>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"pndhzra0wbi"}>
          
          <ColumnWrap className="column__flex el--1" columns={"1"}>
            
            <ColumnWrapper className="--left" style={{"maxWidth":800}}>
              
              <Title className="title-box" content={"Individuální konzultace"}>
              </Title>

              <Text className="text-box" content={"Cena sezení 50 minut / 1000 Kč"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pb--60 pt--60" name={"j7ad9w9z1cr"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--center">
              
              <Text className="text-box fs--16" style={{"maxWidth":654}} content={"Úhrada sezení je možná hotově při každém setkání nebo převodem na účet.<br><br>Domluvený termín sezení je závazný. V případě, že se nemůžete dostavit, je možné zrušit domluvené sezení maximálně do 24 h. V opačném případě požaduji plnou úhradu sezení."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="bg--bottom --parallax pb--80 pt--80" name={"kontakt"} style={{"backgroundColor":"rgba(246,245,241,1)"}} parallax={true}>
          
          <ColumnWrap className="column__flex js-anim  --anim7 --anim-s3 --center el--1" anim={"7"} animS={"3"} columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim7 --anim-s3" anim={"7"} animS={"3"} style={{"maxWidth":1310}}>
              
              <Title className="title-box" content={"Čas pro sebe je ten největší dar, který si můžete dát."}>
              </Title>

              <Button className="btn-box btn-box--shape4" content={"Domluvit schůzku"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"paticka"} style={{"backgroundColor":"rgba(157,150,140,1)"}} layout={"l1"}>
          
          <ColumnWrap className="column__flex --left el--2" style={{"maxWidth":1450}} columns={"2"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box" content={"Terapeutická setkání"}>
              </Subtitle>

              <Title className="title-box" content={"<span style=\"color: var(--color-dominant);\">Ing. Petra Klempířová</span>"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":375}} content={"<span style=\"color: var(--white);\">Těším se na osobní setkání</span>"}>
              </Text>

              <Text className="text-box fs--10" style={{"maxWidth":375}} content={"<span style=\"color: var(--white);\">Vytvořeno přes <a style=\"\" href=\"https://saywebpage.com\">saywebpage.com</a></span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--right" style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box" content={"Kontakt"}>
              </Subtitle>

              <Title className="title-box" content={"<span style=\"color: var(--color-dominant);\">+420 731 228 049</span>"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":""}} content={"<span style=\"color: var(--white);\">Praha, přesné místo bude upřesněno<br>terapeutka.p@gmail.com</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}